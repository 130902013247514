import Nav from './components/Nav';
import WorkCard from './components/Card';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import MCPR from './components/MCPR';
import { Typography } from '@mui/material';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Box sx={{ bgcolor: "#f7f6f5", color: 'black' }}>
            <Nav />
            <Typography variant="h3" align="center" sx={{ mt: 5 }}>Links</Typography>
            <Grid container spacing={4} margin={5}>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="card1.jpeg" title="SKlab gitbhub" description='githubのレポジトリまとめ。ソースコード、マニュアル、プロトコルなどが保管してある。' github_link='https://github.com/SK-Lab-HU' site_link='' />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="list.png" title="顕微鏡予約状況確認ページ" description='顕微鏡使用の予約、削除が可能な予約状況確認ページ' site_link='http://10.32.17.156:3000/slots' github_link='' />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="db.png" title="細胞データベース管理システム" description='細胞のデータベースの一括管理システム' site_link='http://10.32.17.156:3000/' github_link='https://github.com/SK-Lab-HU/CellDBconsole' />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard
                  image="mcp.png"
                  title="増殖曲線自動プロット"
                  description='マイクロプレートリーダーから取得したcsvファイルから各サンプルの非増殖速度を自動計算、プロットまでを行うページ'
                  site_link='http://10.32.17.156:3000/mcpr'
                  github_link='https://github.com/SK-Lab-HU/MicroPlateReader'
                />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="cell.png" title="PeakPathFinder" description='顕微鏡画像から細胞内のGFP等の蛍光局在解析を行うアルゴリズム' site_link='' github_link='https://github.com/SK-Lab-HU/PeakPathAlgorithms' />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="a.png" title="Python設定マニュアル" description='Pythonインストールから基本構文、コードスニペットまでのまとめ' site_link='' github_link='https://github.com/SK-Lab-HU/PythonSetupManual' />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="study.jpeg" title="勉強会資料" description='勉強会資料置き場(ここにReadmeファイルの書き方も置いてある)' site_link='' github_link='https://github.com/SK-Lab-HU/StudySessions' />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="git-feature.png" title="git設定マニュアル" description='vscodeからgithubに変更をpushできるようになるまでのチュートリアル' site_link='' github_link='https://github.com/SK-Lab-HU/GitManual' />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="bioinformatics.jpg" title="Pythonチュートリアル" description='バイオインフォマティクスの基本操作(主に塩基配列操作)を通したpythonの基本構文チュートリアル' site_link='' github_link='https://github.com/SK-Lab-HU/Bioinfo' />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="agarosepad.png" title="アガロースパッドの作成方法" description='顕微鏡での1細胞観察のためのアガロースパッドの作成方法' site_link='' github_link='' />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="nicon.png" title="ND2ToVideo" description='NikonNISから出力された.nd2ファイルからタイムラプス動画(.avi)を出力するツール' site_link='' github_link='https://github.com/SK-Lab-HU/ND2ToVideo' />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="3.png" title="一細胞解析ソフトウェアPhenoPixel4.0" description='顕微鏡データから細胞情報を高速に抽出しデータ解析を自動で行うスクリプト' site_link='https://github.com/ikeda042/PhenoPixel4.0' github_link='' />
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <WorkCard image="sesd.png" title="汎用データ解析スクリプト集" description='PCA、増殖曲線フィッティング、線形最小二乗法、特異値分解等のデータ解析スクリプト' site_link='' github_link='https://github.com/ikeda042/GeneralDataAnalyses' />
              </Grid>
            </Grid >
            <Footer />
          </Box>}>
          </Route>
          <Route path="/mcpr" element={
            <Box sx={{ bgcolor: "#f7f6f5", color: 'black' }}>
              <Nav />
              <MCPR />
              <Footer />
            </Box>
          }>
          </Route>
        </Routes>
      </Router >
    </>
  );
}

export default App;
