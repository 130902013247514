import React, { ChangeEvent, FormEvent, useState } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

function MCPR() {
    const [file, setFile] = useState<File | null>(null);
    const [blankTag, setBlankTag] = useState<string>('');
    const [timespan, setTimespan] = useState<number | string>("");
    const [image, setImage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (!file) return;

        setLoading(true);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('blank_tag', blankTag);
        formData.append('time_span', timespan.toString());

        const response = await axios.post('http://10.32.17.156:8000/mcprapi/mcpr', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob',
        });

        const reader = new FileReader();
        reader.onloadend = function () {
            setImage(reader.result as string);
            setLoading(false);
        }
        reader.readAsDataURL(response.data);
    };

    return (
        <Box
            component="form"
            alignItems="center"
            onSubmit={onSubmit}
            sx={{
                mt: 5,
                minHeight: '100vh' // Add this line
            }}
        >
            <Typography variant="h5" align="center">増殖曲線自動プロット</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {loading ? <CircularProgress /> : image ? (
                    <img src={image} alt="response" style={{ width: '1000px', height: '500px', objectFit: 'contain' }} />
                ) : (
                    <></>
                )}
            </Box>
            <Grid container spacing={4} margin={5}>
                <Grid xs={12}>
                    <input type="file" accept=".csv" onChange={(e: ChangeEvent<HTMLInputElement>) => setFile(e.target.files ? e.target.files[0] : null)} />
                </Grid>
                <Grid xs={6}>
                    <TextField value={blankTag} onChange={(e: ChangeEvent<HTMLInputElement>) => setBlankTag(e.target.value)} placeholder="blank index (例: 3)" fullWidth />
                </Grid>
                <Grid xs={6}>
                    <TextField value={timespan} onChange={(e: ChangeEvent<HTMLInputElement>) => setTimespan(e.target.value)} placeholder="sampling span (sec) (例: 180 三分おき）" type="number" fullWidth />
                </Grid>
                <Grid xs={12}>
                    <Button
                        type="submit"
                        variant='contained'
                        style={{
                            backgroundColor: file ? 'black' : 'gray',
                            color: 'white'
                        }}
                        fullWidth
                        disabled={!file}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default MCPR;
