import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import GitHubIcon from '@mui/icons-material/GitHub';

interface WorkCardProps {
    image: string;
    title: string;
    description: string;
    github_link: string | undefined;
    site_link: string | undefined;
    extraButton?: React.ReactNode; // Add this line
}

export default function WorkCard({ image, title, description, github_link, site_link, extraButton }: WorkCardProps) {
    return (
        <Card sx={{ maxWidth: 460, height: 390, display: 'flex', flexDirection: 'column' }}>
            <CardMedia
                component="img"
                alt={title}
                height="180"
                image={image}
            />
            <CardContent sx={{ overflow: 'auto' }}>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <CardActions sx={{ marginTop: 'auto' }}>
                {github_link ? <Button variant="outlined" href={github_link} target="_blank" startIcon={<GitHubIcon />} sx={{ color: 'black', borderColor: 'black' }}>GitHub</Button> : <div style={{ height: '18px' }} />}
                {site_link ? <Button variant="outlined" href={site_link} target="_blank" sx={{ color: 'black', borderColor: 'black' }}>Website</Button> : <div style={{ height: '18px' }} />}
                {extraButton} {/* Add this line */}
            </CardActions>
        </Card>
    );
}
